import React from "react";
import s from "../SuperAdmin.module.css";
import callApi from "../../../../utils/call-api";
import CRUDGrid from "../../../common/CRUDGrid";
import { validate } from "uuid";

class Settings extends React.Component {
    state = {
        languagesData: [],
        lessonTypeData: [],
        lessonDurationData: [],
        ageTypeData: []
    };

    componentDidMount(){
        this.languagesRefresh();
        this.lessonTypeRefresh();
        this.lessonDurationRefresh();
        this.ageTypeRefresh();
    }

    ageTypeRefresh = ()=>{
        callApi("guide/ageTypes", "GET")
        .then(data=>{
            this.setState({ageTypeData: data.data.ageTypes||[]});
        })
        .catch(err=>{
            console.log(err);
            this.setState({ageTypeData: []});
        });
    }

    createAgeType = async (ageType)=>{
        await callApi("guide/ageTypes", "POST", ageType)
        .then(()=>this.ageTypeRefresh());
    }

    lessonDurationRefresh = ()=>{
        callApi("guide/lessonDurations", "GET")
        .then(data=>{
            this.setState({lessonDurationData: data.data.lessonDurations||[]});
        })
        .catch(err=>{
            console.log(err);
            this.setState({lessonDurationData: []});
        });
    }

    createLessonDuration = async (lessonDuration)=>{
        await callApi("guide/lessonDurations", "POST",lessonDuration)
        .then(()=>this.lessonDurationRefresh());
    }

    lessonTypeRefresh = ()=>{
        callApi("guide/lessonTypes", "GET")
        .then(data=>{
            this.setState({lessonTypeData: data.data.lessonTypes||[]});
        })
        .catch(err=>{
            console.log(err);
            this.setState({lessonTypeData: []});
        });
    }

    createLessonType = async (lessonType)=>{
        await callApi("guide/lessonTypes", "POST", lessonType)
        .then(()=>this.lessonTypeRefresh());
    }

    languagesRefresh = ()=>{
        callApi("guide/languages","GET")
        .then(data=>{
            this.setState({languagesData:data.data.languages||[]});
        }).catch(err=>{
            console.log(err);
            this.setState({languagesData:[]});
        });
    }

    createLanguages = async (language)=>{
        await callApi("guide/languages","POST",language)
        .then(()=>this.languagesRefresh());
    }

    render(){
        return (<div className={s.content_admin}>
            <div style={{width:"50%"}}>
            <CRUDGrid options={{header: {refreshButton:true, removeButton:false, addButton:true, editButton:true},
                title:"Язык обучения",
                columns:[{field: "id", headerName: "id", type: "number", viseble:false},
                    {field: "name", headerName: "Name", type: "text", viseble:true, required: true},
                    {field: "isActive", headerName: "Активный", type: "boolean", viseble:true}]
                }}
                data={this.state.languagesData}
                onRefreshCallBack={this.languagesRefresh}
                onAddCallBack={this.createLanguages}/>
            </div>
            <div style={{width:"50%"}}>
            <CRUDGrid options={{header: {refreshButton:true, removeButton:false, addButton:true, editButton:true},
                title:"Тип урока",
                columns:[{field: "id", headerName: "id", type: "number", viseble:false},
                    {field: "description", headerName: "Описание", type: "text", viseble:true, required: true},
                    {field: "lesson_type", headerName: "Значение", type: "text", viseble:true, required: true}]}}
                
                data={this.state.lessonTypeData}
                onRefreshCallBack={this.lessonTypeRefresh}
                onAddCallBack={this.createLessonType}/>
            </div>
            <div style={{width:"50%"}}>
            <CRUDGrid options={{header: {refreshButton:true, removeButton:false, addButton:true, editButton:true},
                title:"Продолжительность урока",
                columns:[{field: "id", headerName: "id", type: "number", viseble:false},
                    {field: "description", headerName: "Описание", type: "text", viseble:true, required: true},
                    {field: "duration", headerName: "Продолжительность", type: "number", viseble:true, required: true, validate:{min:0}}],
                exceptionTitle: [
                    {errorCode: 404, message: "Duration.missing", title: `Не указано название пакета`},
                    {errorCode:400, message: "Duration.incorrect", tittle: `Не корректное название пакета`}]
                }}
                data={this.state.lessonDurationData}
                onRefreshCallBack={this.lessonDurationRefresh}
                onAddCallBack={this.createLessonDuration}/>
            </div>
            <div style={{width:"50%"}}>
            <CRUDGrid options={{header: {refreshButton:true, removeButton:false, addButton:true, editButton:true},
                title:"Возраста",
                columns:[{field: "id", headerName: "id", type: "number", viseble:false},
                    {field: "description", headerName: "Описание", type: "text", viseble:true, required: true},
                    {field: "age_type", headerName: "Возраст", type: "text", viseble:true, required: true}]
                }}
                data={this.state.ageTypeData}
                onRefreshCallBack={this.ageTypeRefresh}
                onAddCallBack={this.createAgeType}/>
            </div>
        </div>);
    }

}

export default Settings;