import React from 'react'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import BuyClassesItem  from './BuyClassesItem';
import { Portal } from 'react-portal';

import './BuyClasses.scss';
import callApi from '../../../../utils/call-api';
import BUY_CLASSES_OBJ from './BUY_CLASSES_OBJ';
import BUY_CLASSES_OBJ_V2 from './BUY_CLASSES_OBJ_V2';
import ModalCloseButton from "../closeButton/ModalCloseButton";

const DATE_FOR_NEW_TARIFFS = 1690902860; // Tue Aug 01 2023 15:14:20 GMT+0000

class BuyClasses extends React.Component {
    constructor(props) {
        super(props);

        this.userInfo = this.props.userInfo.userInfo;

        let buyClassesArr = JSON.parse(JSON.stringify(BUY_CLASSES_OBJ.groupLevel1));

        if (this.userInfo.creationDate > DATE_FOR_NEW_TARIFFS) {
            buyClassesArr = JSON.parse(JSON.stringify(BUY_CLASSES_OBJ_V2.groupLevel1));
        }

        let actualGroupLevel1Index = null;

        buyClassesArr.forEach((el, i) => {
            el.show = false;
            if (this.userInfo.age >= el.ageFrom && this.userInfo.age <= el.ageTo) {
                el.isActualAge = true;
                // TODO не правильно определяет и выводит актуальны йвозраст
                el.show = true;
                actualGroupLevel1Index = i;
            }
        });
        this.state = {
            isFirstShow: false,
            buyClassesArr,
            actualGroupLevel1Index,
            languages:[],
            ageTypes:[],
            lessonTypes:[],
            lessonDurations:[],
            languageCurrent: null,
            ageTypesCurrent: null,
            lessonTypesCurrent: null,
            lessonDurationsCurrent: null,
            prices:[]

        }
    }

    componentDidMount() {
        Promise.all([
            callApi('guide/ageTypes', 'GET', {}).then(res => {
                console.log(res.data);
                this.setState({ageTypes: res.data.ageTypes}); 
                this.setState({ageTypesCurrent: this.userInfo.ageType});
    
                if (!this.state.ageTypesCurrent){
                    this.setState({ageTypesCurrent: res.data.ageTypes[0].id});
                }
            }).catch((e) => {
                console.log(e);
            }),
            callApi('guide/lessonTypes', 'GET', {}).then(res => {
                console.log(res.data);
                this.setState({lessonTypes: res.data.lessonTypes});
                this.setState({lessonTypesCurrent: res.data.lessonTypes.find(item=>item.lesson_type==="individual")});
            }).catch((e) => {
                console.log(e);
            }),
            callApi('guide/lessonDurations', 'GET', {}).then(res => {
                console.log(res.data);
                this.setState({lessonDurations: res.data.lessonDurations});
                this.setState({lessonDurationsCurrent: res.data.lessonDurations[0]});
            }).catch((e) => {
                console.log(e);
            }),
            callApi('guide/languages', 'GET', {})
            .then(res => res.data.languages)
            .then(languages =>{
                console.log(languages);
                const activLanguages = languages.filter(item=>item.isActive===true);
                this.setState({languages: activLanguages});
                this.setState({languageCurrent: activLanguages.find(item=>item.name==="English")});
            }).catch((e) => {
                console.log(e);
            }),
            callApi('price/getPrices', 'GET', {userId:this.userInfo.id, date: this.userInfo.creationDate}).then(res => {
                console.log(res.data);
                this.setState({prices: res.data.prices}); 
            }).catch((e) => {
                console.log(e);
            })
        ])
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isShowBuyClasses !== this.props.isShowBuyClasses && !this.state.isFirstShow) {
            this.setState({ isFirstShow: true });

            const actualGroupLevel1Index =  this.state.actualGroupLevel1Index ? this.state.actualGroupLevel1Index : 0;

            setTimeout(() => this.showLevel(this.state.buyClassesArr, actualGroupLevel1Index),0);
        }
    }

    buy = () => {
        callApi('dashboard/getSubscriptionPaymentData  ', 'post',
            {subscriptionId: 1}).then(res => {
            const result = res?.data;
            if (result?.ok && result.paymentLink) {
                window.open(result.paymentLink, '_blank');
            }
        }).catch(err => console.log(err));
    }

    showLevel = (arr, groupLevel1Index) => {
        if (!Array.isArray(arr)) return;

        arr.forEach((el, i) => {
            el.show = (i === groupLevel1Index);
            this.setState({ });
        });
    };

    render() {
        const { isShowBuyClasses } = this.props;
        const { isFirstShow, buyClassesArr } = this.state;

        if (!buyClassesArr) return (<></>);

        return (
            <>{isFirstShow &&
                <div className="CustomJumpModal BuyClassesContainer">
                    <div className={'CustomJumpModalBody BuyClassesBody' +
                        (isShowBuyClasses ? ' CustomJumpModalBodyShow' : '')}
                    >
                        <ModalCloseButton
                            closeModal={this.props.closeModal}
                        />

                        {this.state.languages.length>1 && this.state.languageCurrent && <div className='BuyClassesLevGroup3'>
                            {this.state.languages.map(lang=>{
                                return <div className={"BuyClassesLev3 "+((lang.id === this.state.languageCurrent.id) ? "BuyClassesLev3Curr":"")}
                                            onClick={()=>{
                                                this.state.languageCurrent=lang;
                                                this.setState({ });
                                                }}>
                                    {lang.name}
                                </div>
                            })}                            
                        </div>}
                        <div className='BuyClassesLevGroup3'>
                            {this.state.lessonDurationsCurrent && this.state.lessonDurations.map(lesson=>{
                                return <div className={"BuyClassesLev3 "+((lesson.id === this.state.lessonDurationsCurrent.id) ? "BuyClassesLev3Curr":"")}
                                            onClick={()=>{
                                                this.state.lessonDurationsCurrent=lesson;
                                                this.setState({ });
                                                }}>
                                    <div>{lesson.duration}</div>
                                    <div>минут</div>
                                </div>
                            })}                            
                        </div>
                        {this.state.prices && this.state.languageCurrent && <div id='BuyClassesLevItems'>
                            {this.state.prices
                            .filter(item=>item.package.ageType.id ===this.state.ageTypesCurrent)
                            .filter(item=>item.package.language.id ===this.state.languageCurrent.id)
                            .filter(item=>item.package.duration.id ===this.state.lessonDurationsCurrent.id)
                            .filter(item=>item.package.lessonType.id ===this.state.lessonTypesCurrent.id)
                            .map(item=><BuyClassesItem priceItemInfo={item}></BuyClassesItem>)}

                        </div>}
                    </div>
                </div>
            }</>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { },
    dispatch
);

const mapStateToProps = state => (
    {
        userInfo: state.auth.userInfo,
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyClasses));
