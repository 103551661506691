import React, { useState, useEffect } from "react";
import {NotificationContainer, NotificationManager} from 'react-notifications';

// import { useRef } from 'react';
import "./dictionary.scss";
import { MyWordDataEN as MyWordDataEN_ } from "./myWordDataEN";
import { MyWordDataRU as MyWordDataRU_ } from "./myWordDataRU";
import callApi from '../../utils/call-api';
import { useDispatch, useSelector } from "react-redux";
import * as types from '../../constants/dictionary';
import ArrowImg from '../../assets/images/arrow_123.png';
import dictionaryWordSentSound from '../../../src/assets/sounds/dictionary_word_sent.mp3';
import imgBlueHeart from './imgs/heart_blue.png';
import imgSave from './imgs/save.png';
import imgHeart from './imgs/heart.png';

import shareDictionary from './imgs/share_dictionary.png';
import IntlMessages from '../../utils/IntlMessages';
import lang from '../../utils/lang';

const dictionaryWordSentAudio = new Audio();
dictionaryWordSentAudio.src = dictionaryWordSentSound;

const Dictionary = ({usersInfo, socket, userInfo, virtualClassId, mouseMoveStatusFunc, selectedWordToTranslate}) => {
    const [sortActive, setSortActive] = useState(false);
    const [MyWordDataEN, setMyWordDataEN] = useState(MyWordDataEN_);
    const [MyWordDataRU, setMyWordDataRU] = useState(MyWordDataRU_);
    const [wordEngActive, setWordEngActive] = useState(false);
    const [wordRusActive, setWordRusActive] = useState(false);
    const [langActive, setLangActive] = useState(false);
    const [dictActive, setDictActive] = useState(false);
    const [langRusActive, setLangRusActive] = useState(false);
    const [langFraActive, setLangFraActive] = useState(false);
    const [wordActive, setWordActive] = useState([]);
    const [removeWordName, setRemoveWordName] = useState('');
    const [wordTrans, setWordTrans] = useState([]);
    const [dictionaryActive, setDictionaryActive]= useState('en-ru');
    const dateNow = new Date();
    const [wordTranscription, setWordTranscription] = useState([]);
    const [sortWord, setSortWord] = useState();
    const [sortData, setSortData] = useState();
    const openClose = useSelector(state => state.dictionary.openCloseDict);

    const selectedObj = useSelector(state => state.dictionary.selectedObj);
    const dispatch = useDispatch();
    const [display, setDisplay] = useState('none');
    const [topPx, setTopPx] = useState(-630);
    const [transitionMs] = useState('200ms');
    const [pxRightPanel, setPxRightPanel] = useState(610);
    const [pxRightPanelWidth, setPxRightPanelWidth] = useState(0);
    const [rightPanelDisplay, setRightPanelDisplay] = useState('none');
    const [rightPanelContent, setRightPanelContent] = useState(-200);
    const [pxLeftPanel, setPxLeftPanel] = useState(250);
    const [pxLeftPanelWidth, setPxLeftPanelWidth] = useState(0);
    const [leftPanelDisplay, setLeftPanelDisplay] = useState('none');
    const [leftPanelContent, setLeftPanelContent] = useState(250);
    const [sound, setSound] = useState();
    const windowInnerWidth = useSelector(state => state.settings.width);
    const isReload = useSelector(state => state.dictionary.isReload);

    function load(){
        callApi('other/getTranslate', 'POST',{
            isGet: true,
        }).then(res => {
            if (res && res.data.translateData && res.data.translateData.translates) {
                let sum = 0;
                if (res.data.translateData.translates.MyWordDataEN) {
                    setMyWordDataEN(res.data.translateData.translates.MyWordDataEN);
                    sum += res.data.translateData.translates.MyWordDataEN.length;
                }

                if (res.data.translateData.translates.MyWordDataRU) {
                    setMyWordDataRU(res.data.translateData.translates.MyWordDataRU);
                    sum += res.data.translateData.translates.MyWordDataRU.length;
                }

                dispatch({ type: types.WORDS_COUNT, payload: sum });
            }

        }).catch(() => {});
    }

    useEffect(() => {
        if (openClose) {
            setDisplay('flex')
            if (topPx < 0) {
                setTimeout(setTopPx, 50, 60)
            } else if (topPx > 15 && topPx <= 60) {
                setTimeout(setTopPx, 350, 15)
            }
        } else {
            if (topPx === 15) {
                setTimeout(setTopPx, 50, 60)
            } else {
                setTimeout(setTopPx, 350, -630);
            }
            setTimeout(setDisplay, 550, 'none');
        }
    }, [topPx, openClose])

    useEffect(() => {
        load();
        // callApi('other/getTranslate', 'POST',{
        //     isGet: true,
        // }).then(res => {
        //     if (res && res.data.translateData && res.data.translateData.translates) {
        //         let sum = 0;
        //         if (res.data.translateData.translates.MyWordDataEN) {
        //             setMyWordDataEN(res.data.translateData.translates.MyWordDataEN);
        //             sum += res.data.translateData.translates.MyWordDataEN.length;
        //         }

        //         if (res.data.translateData.translates.MyWordDataRU) {
        //             setMyWordDataRU(res.data.translateData.translates.MyWordDataRU);
        //             sum += res.data.translateData.translates.MyWordDataRU.length;
        //         }

        //         dispatch({ type: types.WORDS_COUNT, payload: sum });
        //     }

        // }).catch(() => {});
    }, [])

    if (sortWord) {
        MyWordDataEN.sort(function (a, b) {
            const nameA = a.id.toLowerCase();
            const nameB = b.id.toLowerCase();

            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        });

        MyWordDataRU.sort(function (a, b) {
            const nameA = a.id.toLowerCase();
            const nameB = b.id.toLowerCase();

            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            return 0
        });
    } else if (sortWord === false) {
        MyWordDataEN.sort(function (a, b) {
            const nameA = a.id.toLowerCase();
            const nameB = b.id.toLowerCase();
            if (nameA > nameB) {
                return -1
            }
            if (nameA < nameB) {
                return 1
            }
            return 0
        });

        MyWordDataRU.sort(function (a, b) {
            const nameA = a.id.toLowerCase();
            const nameB = b.id.toLowerCase();
            if (nameA > nameB) {
                return -1
            }
            if (nameA < nameB) {
                return 1
            }
            return 0
        });
    }

    function sortByAlphabet() {
        setSortData('')
        if (sortWord === null) {
            setSortWord(true)
        } else if (sortWord === true) {
            setSortWord(false)
        } else {
            setSortWord(true)
        }
    }

    if (sortData === true) {
        MyWordDataEN.sort(function (a, b) {
            const dateA = new Date(a.data);
            const dateB = new Date(b.data);

            return dateA - dateB
        });

        MyWordDataRU.sort(function (a, b) {
            const dateA = new Date(a.data);
            const dateB = new Date(b.data);

            return dateA - dateB
        });
    } else if (sortData === false){
        MyWordDataEN.sort(function (a, b) {
            const dateA = new Date(a.data);
            const dateB = new Date(b.data);
            return dateB - dateA
        });

        MyWordDataRU.sort(function (a, b) {
            const dateA = new Date(a.data);
            const dateB = new Date(b.data);
            return dateB - dateA
        });
    }

    function sortByData () {
        setSortWord('');
        if (sortData === null) {
            setSortData(true);
        } else if (sortData === true) {
            setSortData(false);
        } else {
            setSortData(true);
        }
    }

    const myWordEN = MyWordDataEN.map(item=> {
            return (
                <li key={item.id} onClick={() => {
                    selectedWordToTranslate(item.id);
                    setDictionaryActive('en-ru');
                    setWordActive(item.id);
                    setWordTranscription(item.ts);
                    setRemoveWordName(item.id);
                }}>{item.id}</li>
            )
        }
    );

    const myWordRU = MyWordDataRU.map(item => {
            return (
                <li key={item.id} onClick={() => {
                    selectedWordToTranslate(item.id);
                    setDictionaryActive('ru-en');
                    setWordActive(item.id);
                    setRemoveWordName(item.id);
                }}>{item.id}</li>
            )
        }
    );

    const wordTranslation = wordTrans.map(item =>{
        return (
            <li key={item.text}>
                {item.text}
            </li>
        )
    });

    function openCloseSort() {
        if (sortActive === true){
            setSortActive(false);
        } else {setSortActive(true)}
    }

    function openCloseWordEng() {
        if (wordEngActive === true){
            setWordEngActive(false);
        } else {setWordEngActive(true)}
    }

    function openCloseWordRus() {
        if (wordRusActive === true){
            setWordRusActive(false);
        } else {setWordRusActive(true)}
    }

    function openCloseDict() {
        if (dictActive === true){
            setDictActive(false);
        } else {setDictActive(true)}
    }

    function closeDict() {
        dispatch({type:types.CLOSE_DICTIONARY});
        setDictActive(false);
        setLangActive(false);
    }

    function openCloseLangRus() {
        if (langRusActive === true){
            setLangRusActive(false);
        } else {setLangRusActive(true)}
    }

    function openCloseLangFra() {
        if (langFraActive === true) {
            setLangFraActive(false);
        } else {
            setLangFraActive(true)
        }
    }

    async function saveWordForAllStudents() {
        
        return await callApi('other/addWordToUsersDictionary', 'POST', {
            "vlId": virtualClassId,
            'text': wordActive,
            'language': dictionaryActive
        })
        .then(()=>{
            sendMessageToReloadDictionary();
            return NotificationManager.info('Слово добавленно в словари', '', 1000);
        })
        .catch(err=>{
            console.log(err);
            return NotificationManager.error('произошла ошибка','Слово Не добавленно', 1500);
        });        
    }

    function saveWord() {
        if (dictionaryActive === 'ru-en') {
            const arrIndex = MyWordDataRU.findIndex(el => el.id === wordActive);
            if (arrIndex === -1) {
                MyWordDataRU.push({id: wordActive, data: dateNow});
                localStorage.setItem('MyWordDataRU', JSON.stringify(MyWordDataRU));
                setRemoveWordName(wordActive);
                dispatch({type: types.WORDS_COUNT, payload: MyWordDataRU.length + MyWordDataEN.length});
            } else if (removeWordName === wordActive) {
                MyWordDataRU.splice(arrIndex, 1);
                localStorage.setItem('MyWordDataRU', JSON.stringify(MyWordDataRU));
                setRemoveWordName('');
                dispatch({type: types.WORDS_COUNT, payload: MyWordDataRU.length + MyWordDataEN.length});
            }
        } else if (dictionaryActive === 'en-ru') {
            const arrIndex = MyWordDataEN.findIndex(el => el.id === wordActive);
            if (arrIndex === -1) {
                MyWordDataEN.push({id: wordActive, data: dateNow});
                localStorage.setItem('MyWordDataEN', JSON.stringify(MyWordDataEN));
                setRemoveWordName(wordActive);
                dispatch({type: types.WORDS_COUNT, payload: MyWordDataEN.length + MyWordDataRU.length});
            } else if (removeWordName === wordActive) {
                MyWordDataEN.splice(arrIndex, 1);
                localStorage.setItem('MyWordDataEN', JSON.stringify(MyWordDataEN));
                setRemoveWordName('');
                dispatch({type: types.WORDS_COUNT, payload: MyWordDataEN.length + MyWordDataRU.length});
            }
        }

        setTimeout(saveDictionaryToServer, 300);
    }

    function saveDictionaryToServer () {
        callApi('other/getTranslate', 'POST',{
            isGet: false,
            translates: {
                MyWordDataEN,
                MyWordDataRU,
            }
        });
    }

    function activeDictionary(target) {
        setDictionaryActive(target);
        setWordActive([]);
        setWordTrans([]);
        setWordTranscription([]);
        dispatch({type:'SELECTION_WIN', payload: ''});
    }

    const [oldWord, setOldWord] = useState('');

    useEffect(() => {
        setOldWord(selectedObj);

        setTimeout(() => {
            setOldWord(val => {
                if (val === selectedObj) {
                    searchWord({target: {value: selectedObj}});
                }

                return val;
            });
        }, 1000);

    }, [selectedObj]);

    // const isRequest = useRef(false);

    async function searchWord(event) {
        const word = (event?.target?.value || '').trim();
        if (!word || word.length < 2) return;
        setWordActive(word);

        // if (dictionaryActive === 'en-ru') {
        //     await callApi('other/getTranslateLingvo', 'POST',{
        //         'type': 'sound',
        //         'dictionaryName': 'LingvoUniversal (En-Ru)',
        //         'fileName': word + '.wav'
        //     } ).then(res => {
        //         setSound(res.data.translateData)
        //     }).catch(() => {})
        // }

        const sendRes = async () => {
            // isRequest.current = true;

            await callApi('other/getTranslateYandex', 'POST',{
                'text': word,
                'language': dictionaryActive
            } ).then(res => {
                // isRequest.current = false;

                if (res && res.data.translateData) {
                    if (MyWordDataEN.some(a => a.id === res.data.translateData.def[0].text)) {
                        setRemoveWordName(res.data.translateData.def[0].text)
                    } else {
                        setRemoveWordName('')
                    }
                    setWordTranscription(res.data.translateData.def[0].ts);
                    setWordActive(res.data.translateData.def[0].text);
                    setWordTrans(res.data.translateData.def[0].tr);
                }
            }).catch(() => {
                // isRequest.current = false;
            });
        }

        sendRes();
    }

    function showDictionaryForUsers(userEmail = 'all') {
        socket.send(JSON.stringify({
            command: 'showDictionaryForUsers',
            data: {
                userEmail,
                dictionaryWord: selectedObj,
            },
            val: {
                vCId: virtualClassId,
                userEmail: userInfo.userInfo.email,
                userType: userInfo.userInfo.type,
            }
        }));
    }

    function sendMessageToReloadDictionary(userEmail = 'all') {
        socket.send(JSON.stringify({
            command: 'reloadDictionary',
            data: {
                userEmail
            },
            val: {
                vCId: virtualClassId,
                userEmail: userInfo.userInfo.email,
                userType: userInfo.userInfo.type,
            }
        }));
    }

    function studentsListToShowWord() {
        return (
            <div>
                {Object.values(usersInfo).map(item => {
                    if (userInfo.userInfo.email === item.userEmail)
                        return (<React.Fragment key={item.userEmail}/>);

                    return (
                        <div
                            onClick={() => showDictionaryForUsers(item.userEmail)}
                            key={item.userEmail}
                            className='students_list_show'>
                            {item.userName}
                        </div>
                    )
                })}
            </div>
        )
    }

    useEffect(() => {
        if (dictActive) {
            if (pxLeftPanel === 250 || pxLeftPanel === 200) {
                setTimeout(setLeftPanelDisplay, 50, 'flex')
                if (windowInnerWidth <= 1200) {
                    setTimeout(setLeftPanelContent, 120, 0)

                    setTimeout(setPxLeftPanelWidth, 100, 200)
                } else {
                    setTimeout(setLeftPanelContent, 120, 0)

                    setTimeout(setPxLeftPanelWidth, 100, 250)
                }
                setTimeout(setPxLeftPanel, 100, -40)
                setTimeout(setPxLeftPanel, 300, 0)
            }
        } else if (pxLeftPanel === 0) {
            setTimeout(setPxLeftPanel, 50, -40)
            setTimeout(setPxLeftPanel, 250, 250)
            setTimeout(setLeftPanelContent, 230, 250)

            setTimeout(setPxLeftPanelWidth, 250, 0)
            setTimeout(setLeftPanelDisplay, 450, 'none')
        }
    }, [pxLeftPanel, dictActive, windowInnerWidth])

    useEffect(() => {
        if(isReload){
            load();
        }
    },[isReload]);


    useEffect(() => {
        if (langActive) {
            if (windowInnerWidth <= 1200) {
                setTimeout(setPxRightPanel, 10, 480)
                setTimeout(setPxRightPanelWidth, 100, 140)
            } else {
                setTimeout(setPxRightPanel, 10, 610)
                setTimeout(setPxRightPanelWidth, 100, 200)
            }
            if (pxRightPanelWidth === 0) {
                setTimeout(setRightPanelDisplay, 50, 'flex')
                if (windowInnerWidth <= 1200) {
                    setTimeout(setRightPanelContent, 120, 0)
                    setTimeout(setPxRightPanelWidth, 100, 140)
                    setTimeout(setPxRightPanel, 100, 520)
                    setTimeout(setPxRightPanel, 300, 480)
                } else {
                    setTimeout(setRightPanelContent, 120, 0)
                    setTimeout(setPxRightPanelWidth, 100, 200)
                    setTimeout(setPxRightPanel, 100, 650)
                    setTimeout(setPxRightPanel, 300, 610)
                }
            }
        } else if (pxRightPanelWidth === 200 || pxRightPanelWidth === 140) {
            if (windowInnerWidth <= 1200) {
                setTimeout(setPxRightPanel, 50, 520)
                setTimeout(setPxRightPanel, 250, 480)
            } else if (windowInnerWidth >= 1200) {
                setTimeout(setPxRightPanel, 50, 650)
                setTimeout(setPxRightPanel, 250, 610)
            }
            setTimeout(setRightPanelContent, 250, -200)
            setTimeout(setPxRightPanelWidth, 230, 0)
            setTimeout(setRightPanelDisplay, 450, 'none')
        }
    }, [pxRightPanel, langActive, windowInnerWidth])

    function playSound() {
        const soundTranscription = new Audio('data:audio/wav;base64,' + sound)
        soundTranscription.play(); soundTranscription.volume = 0.1;

    }

    console.log('removeWordName', removeWordName)

    return (
        <div className='dict_container_main'
             style={{display: display}}
             onMouseDown={() => {
                console.log(`${(new Date()).getTime()} dictionary mouseDown`);
                 mouseMoveStatusFunc(true)
             }}
             onMouseEnter={() => {
                console.log("dictionary mouseEnter");
                 mouseMoveStatusFunc(false)
             }}
             onClick={() => {
                console.log("dictionary mouseClick");
                 mouseMoveStatusFunc(false)
             }}
        >
            <div className="dict_container" style={{top: topPx, transition: transitionMs}} onClick={e => e.stopPropagation()}>

                {/*Left panel*/}
                <div
                    className='container_word'
                    style={{display: leftPanelDisplay, left: pxLeftPanel,
                        // width: pxLeftPanelWidth,
                        transition: transitionMs}}
                >
                    <div
                        className='dictionary_word'
                        style={{left: leftPanelContent, transition: transitionMs}}
                        onClick={e => e.stopPropagation()}
                    >
                        <div className={sortActive ? "modal_sort active" : "modal_sort"}>
                            <div className={sortActive ? "sort active" : "sort"}>
                                <div className="sort_header">
                                    Сортировать по
                                </div>
                                <div className="sort_button">
                                    <button onClick={sortByAlphabet}>алфавиту</button>
                                    <button onClick={sortByData}>дате</button>
                                </div>
                            </div>
                        </div>
                        <div className='word_eng_header'>
                            <div className='name_eng'>
                                <IntlMessages id="Англо-русский" />
                            </div>
                            <button
                                style={{ opacity: 0, pointerEvents: 'none' }}
                                className='dict_content_sort dict_content_btn' onClick={openCloseSort}
                            />
                            <button
                                className={wordEngActive ? "dict_content_open dict_content_btn active" : "dict_content_open dict_content_btn"}
                                onClick={openCloseWordEng}
                            >
                                <img src={ArrowImg} />
                            </button>
                        </div>

                        <div className={wordEngActive ? "word_eng active" : "word_eng"}>
                            <ul>
                                {myWordEN}
                            </ul>
                        </div>

                        <div className='dict_content_rus'>
                            <div className='dict_name_rus'>
                                <IntlMessages id="Русско-английский" />
                            </div>
                            <button
                                className={wordRusActive ? "dict_content_open dict_content_btn active" : "dict_content_open dict_content_btn"}
                                onClick={openCloseWordRus}
                            >
                                <img src={ArrowImg} />
                            </button>
                        </div>
                        <div className={wordRusActive ? "word_rus active" : "word_rus"}>
                            <ul>
                                {myWordRU}
                            </ul>
                        </div>
                    </div>
                </div>


                {/*Main panel*/}

                <div className='dictionary_cont' onClick={e => e.stopPropagation()} >
                    <div className='content'>

                        <div className='header'>
                            <div className='modal_header'>
                                <button title={lang("Сохранить в свой словарь")}
                                        className='open_close_dict dict_content_btn'
                                        onClick={openCloseDict}/>
                                {dictionaryActive === 'en-ru' ? <p>{lang('Англо - русский')}</p> : null}
                                {dictionaryActive === 'ru-en' ? <p>{lang('Русско - английский')}</p> : null}
                                <div style={{display: 'flex'}}>
                                    <button title={lang("Переключить языки")}
                                            className='dict_content_sort dict_content_sort_revers dict_content_btn'
                                            onClick={() => {
                                                if (dictionaryActive === 'ru-en') {
                                                    setDictionaryActive('en-ru');
                                                } else if (dictionaryActive === 'en-ru') {
                                                    setDictionaryActive('ru-en');
                                                }
                                            }}/>
                                    {/*<button className='open_close_lang dict_content_btn' onClick={openCloseLang}>*/}
                                    {/*    <hr color='#000'/>*/}
                                    {/*    <hr color='#000'/>*/}
                                    {/*    <hr color='#000'/>*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                        </div>

                        <div className='modal_search'>
                            <input
                                id='search_input'
                                type='text'
                                className='modal_input'
                                placeholder="Search a word"
                                value={selectedObj}
                                onInput={event => dispatch({type:'SELECTION_WIN', payload: event && event.target && event.target.value})}
                                onKeyDown={e => {
                                    if (e.target.value.length >= 2 && e.key === 'Enter')
                                        searchWord(e || ({target: {value: selectedObj}}));
                                    setRemoveWordName('');
                                }}
                            />
                        </div>

                        {/*<div className='word_img'>*/}
                        {/*    <img src='' alt=""/>*/}
                        {/*</div>*/}

                        <div className='word_content'>
                            <div className='word_description'>
                                {wordActive.length !== 0 && dictionaryActive !== 'ru-en'?
                                    <ul>
                                        <p>
                                            {wordActive} ({wordTranscription}) {sound ?
                                            <div
                                                className='sound_play'
                                                onClick={playSound}
                                            > </div>
                                            : null}
                                        </p>
                                        <div className="wordTranslation">
                                            {wordTranslation}
                                        </div>

                                    </ul>
                                    :
                                    <ul>
                                        <p>{wordActive}</p>
                                        <div className="wordTranslation">
                                            {wordTranslation}
                                        </div>
                                    </ul>}

                            </div>
                        </div>
                        <div className='modal_footer'>
                            <button
                                // title={!removeWordName ? 'Добавить в словарь' : 'Удалить из словаря'}
                                className={'dictionary_show' + (removeWordName ? ' dictionary_remove' : '') + (virtualClassId ? '' : ' dict_virtual')}
                                onClick={saveWord}
                            >
                                {!removeWordName ?
                                    <img className='img_heart' src={imgHeart} alt="" /> :
                                    <img src={imgBlueHeart} alt="" />
                                }
                            </button>
                            {virtualClassId&&(userInfo.userInfo.type === 'teacher') ?
                                <button
                                    title={'Добавить в словарь Ученикам'}
                                    className='dictionary_show'
                                    onClick={saveWordForAllStudents}
                                >
                                    <img src={imgSave} alt="" />
                                </button> : null                            
                            }    
                            {virtualClassId ?
                                <button
                                    // title="Показать всем"
                                    className="dictionary_show"
                                    onClick={() => showDictionaryForUsers()}
                                >
                                    {/* <div className='dictionary_show_btn'> */}
                                        <img src={shareDictionary} alt="ПОКАЗАТЬ" />
                                    {/* </div> */}
                                </button>
                                : null}                             
                        </div>

                        <div className='dictionary_close'>
                            <button className='dictionary_close_btn'
                                    onClick={() => {
                                        
                                        console.log(`${(new Date()).getTime()} dictionary close`);
                                        closeDict();
                                        console.log(`${(new Date()).getTime()} dictionary close_finish`);
                                        setTimeout(()=>{
                                            console.log(`${(new Date()).getTime()} dictionary close_finish`);
                                            mouseMoveStatusFunc(false);
                                        }, 600);
                                    }}/>
                        </div>
                    </div>
                </div>
                {/*Right panel*/}
                <div
                    className='container_lang'
                    style={{display: rightPanelDisplay, left: pxRightPanel, width: pxRightPanelWidth, transition: transitionMs}}
                >
                    <div
                        className='dictionary_lang'
                        style={{left: rightPanelContent, transition: transitionMs}}
                        onClick={e => e.stopPropagation()}
                    >
                        <div className='lang_content_rus'>
                            <div className='lang_name_rus'>Русско-</div>
                            <button
                                className={langRusActive ? "dict_content_open dict_content_btn active" : "dict_content_open dict_content_btn"}
                                onClick={openCloseLangRus}
                            >
                                <img src={ArrowImg} />
                            </button>
                        </div>
                        <div className={langRusActive ? "lang_rus active" : "lang_rus"}>
                            <ul>
                                <li onClick={() => activeDictionary('ru-en')}>английский</li>
                                <li>немецкий</li>
                                <li>французкий</li>
                                <li>китайский</li>
                            </ul>
                        </div>

                        <div className='lang_content_fra'>
                            <div className='lang_name_fra'>Англо-</div>
                            <button
                                className={langFraActive ? "dict_content_open dict_content_btn active" : "dict_content_open dict_content_btn"}
                                onClick={openCloseLangFra}
                            >
                                <img src={ArrowImg} />
                            </button>
                        </div>
                        <div className={langFraActive ? "lang_fra active" : "lang_fra"}>
                            <ul>
                                <li onClick={() => activeDictionary('en-ru')}>русский</li>
                                <li>немецкий</li>
                                <li>русский</li>
                                <li>китайский</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </div>            
        </div>
    );
}
export default Dictionary;