import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import callApi from "../../../../utils/call-api";
import Button from '@material-ui/core/Button/Button';
import './BuyClasses.scss';
import InfoDialog from '../InfoDialog';
import lang from '../../../../utils/lang';

class BuyClassesItem extends React.Component {
    constructor(props) {
        super(props);
    }

    buyPackage(p) {
        console.log(p);
        callApi('dashboard/getSubscriptionPaymentData', 'post',
            { priceInfo: p }).then(res => {
            const result = res?.data;
            console.log(result.paymentLink);
            if (result?.ok && result.paymentLink) {
                window.open(result.paymentLink, '_blank');
            }
        }).catch(err => console.log(err));
    };

    render() {
        const { priceItemInfo} = this.props;
        return (
                <div class="BuyClassesItem BuyClassesItemScale">
                    {Boolean(priceItemInfo.package.hit)&&<div class="TopRightText">Хит</div>}
                    
                    <div class="CenterBlock">
                        <div class="CenterHeader">{priceItemInfo.package.count}</div>
                        <div class="CenterInfo">{`${priceItemInfo.package.lessonType.description} занятий`}</div>
                        <div class="CenterComment">{`(по ${priceItemInfo.package.duration.duration} минут)`}</div>
                    </div>
                    <div class="CenterFooter">{`${lang("ОБЩАЯ СТОИМОСТЬ:")}`}</div>
                    <div class="CenterFooter">{`${priceItemInfo.price} руб`}</div>
                    <div class="FooterBlock">
                        <div class="FooterTopText">{lang("Стоимость каждого занятия:")}</div>
                        <div class="FooterPrice">{`${priceItemInfo.price/priceItemInfo.package.count} руб`}</div>
                        <div class="FooterButton BuyClassesItemScale"
                            onClick={()=>this.buyPackage(priceItemInfo)}>{lang("ПРИОБРЕСТИ")}
                            
                        </div>
                        {priceItemInfo.discount !=0 &&<div class="FooterBottomRed">{`Экономия ${priceItemInfo.discount}%`}</div>}
                    </div>
                </div>
            );
    };
}

export default BuyClassesItem;
