import React from "react";
import s from "../SuperAdmin.module.css";

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PricePackages from "./PricePackages";
import ManualPricis from "./ManualPricis";


class Finance extends React.Component { 
    state = {        
        value: '1'
    };

    handleChange = (event, newValue) => {
        this.setState({value:newValue});
      };

    render(){
        return (<div className={s.content_admin}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={this.handleChange} aria-label="lab API tabs example">
                            <Tab label="Прайсы" value="1" />
                            <Tab label="Цены Студентам" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <PricePackages/>
                    </TabPanel>
                    <TabPanel value="2">
                        <ManualPricis/>
                    </TabPanel>        
                </TabContext>
            </Box>            
        </div>);
    }
}

export default Finance;