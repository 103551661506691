import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

/**
 * A Material-UI dialog that shows an error message.
 *
 * @param {bool} open - Whether the dialog is open or not.
 * @param {func} onClose - The function to call when the dialog is closed.
 * @param {string} message - The message to display in the dialog.
 *
 * @returns A Material-UI <Dialog /> component.
 */
const ErrorDialog = ({ open, onClose, message }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Ошибка</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;