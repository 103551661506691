import React, { useState, useEffect } from 'react';
import "./ObjectDialog.scss";
import MaterialTextField from '@material-ui/core/TextField';
import { MenuItem, Select, Button, FormControlLabel, Checkbox  } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import ErrorDialog from './ErrorDialog';


const ObjectDialog = ({options, value, onOkClickCallBack, onCanselClickCallBack})=>{
    const [title] = useState(options.headerName);
    const [columns] =useState(options.columns);
    const [exceptionTitle] = useState(options.exceptionTitle);
    const [objectValue, setObjectValue] = useState(value);
    const [isShowExceptionDialog, setIsShowExceptionDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
 
    useEffect(() => {
        getEmptyObject();
      }, [value]);

    const getEmptyObject=()=>{
        if (Object.keys(value).length==0){
            const v ={};
            columns.forEach(column => 
                column.defaultValue? v[column.field]=column.defaultValue:
                    column.type==="number"?v[column.field]=0:
                        column.type==="boolean"?v[column.field]=false: v[column.field]="");
            setObjectValue(v);
        }
    } 
    

    const onOkClick= async ()=>{
        try {

            const validException = validate(objectValue);
            if(validException.length>0){
                setErrorMessage(validException.reduce((acc, item) => acc + '\n' + item, ''));
                setIsShowExceptionDialog(true);
                return;
            }
            await onOkClickCallBack(objectValue);
        } catch (error) {
            const exacptionInfo = getExceptionInfo(error);
            if (exacptionInfo){
                setErrorMessage(exacptionInfo.tittle);
            } else{
                setErrorMessage("Ошибка");
            }       
            setIsShowExceptionDialog(true);     
        }
    }

    const validate = (value) => {
        return columns.filter(item=>item.validate)
        .reduce((acc, item) => {
            if(item.validate.required && (item.type!="number" && value[item.field] === "")){
                acc.push(`${item.headerName} не заполнен`);
            }
            if (item.type==="number" && isNaN(Number(value[item.field]))){
                acc.push(`${item.headerName} не является числом`);
            }
            if(item.type==="number" && value[item.field] && Number(value[item.field])<item.validate.min){
                acc.push(`${item.headerName} меньше минимального значения ${item.validate.min}`);
            }
            if(item.type==="number" && value[item.field] && Number(value[item.field])>item.validate.max){
                acc.push(`${item.headerName} больше максимального значения ${item.validate.max}`);
            }
            return acc;
        },[]);
    }

    const getExceptionInfo = (error) => {
        if (!Array.isArray(exceptionTitle)) {
            return false;
        }
        return exceptionTitle
        .find(item=>item.errorCode ==error.response.status && item.message==error.response.data.message);       
    }


    const onCanselClick=()=>{
        onCanselClickCallBack();
    }

    const change =(name, value)=>{
        let clone = { ...objectValue }
        clone[name]= columns.find(item=>item.field===name).type==="boolean" ? value.target.checked: value.target.value;
        setObjectValue(clone);
    }

    const comboboxChange = (name, value, list)=>{
        const elementProperty = columns.find(item=>item.field===name);
        const elementValue = list.find(item=>item[elementProperty.objectId]===value.target.value)[elementProperty.objectId];
        let clone = { ...objectValue }
        clone[name]=elementValue;
        setObjectValue(clone);
    }

    const onExceptionDialogClose = () => {
        setIsShowExceptionDialog(false);
    }

    const validateColumn = (v, type, rules)=>{
        if (type=="string"){
            return !v.match(rules);
        }
        if (type=="number"){
            if (isNaN(Number(v))){
                return true;
            }
            if (Number(v)<rules.min){
                return true;
            }
            if (Number(v)>rules.max){
                return true;
            }   
        }
        return false;
    }

    return (<>
        <div className='object-dialog-contener'>
            <div className='object-dialog'>
                <div className='object-title'>{title}</div>
                <div className='object-body'>
                    {columns.filter(item=>item.viseble).map(element => {
                        if (element.type==="combobox") {
                            const v= objectValue[element.field];
                            return (
                                <>
                                    <InputLabel id={`select-label_${element.field}`}>{element.headerName}</InputLabel>
                                    <Select
                                        labelId={`select-label_${element.field}`}
                                        id={`select_${element.field}`}
                                        value={v}
                                        label={element.headerName}
                                        onChange={val => comboboxChange(element.field, val, element.list)}>
                                        {element.list.map(item=>{
                                            return <MenuItem value={item[element.objectId]}>{item[element.objectValue]}</MenuItem>
                                            }                                        
                                        )}                                    
                                    </Select>
                                </>
                            );

                        } else {
                            if (element.type==="boolean") {
                                const v= objectValue[element.field];
                                return (
                                    <FormControlLabel control={<Checkbox  onChange={val => change(element.field, val)} checked={v} />} label={element.headerName} />
                                );

                            } else{
                                const v= objectValue[element.field];
                                return (
                                    <MaterialTextField
                                        id = {element.id}
                                        required = {element.required}
                                        error = {element.validate && validateColumn(v, element.type, element.validate)}
                                        label={element.headerName}
                                        type={element.type}
                                        value={v}
                                        onChange={val => change(element.field, val)}
                                        className='object-TextField'
                                    />
                                    
                                );
                            }
                        }
                        
                    })}
                </div>
                <div className='object-footer'>
                    <Button
                        onClick={onOkClick}
                        className="object-ControlButtons"
                    >
                        OK
                    </Button>
                    <Button onClick={onCanselClick} className="object-ControlButtons">Cansel</Button>
                </div>
            </div>
            <ErrorDialog open={isShowExceptionDialog} onClose={onExceptionDialogClose} message={errorMessage}/>    
        </div>        
    </>);
}

export default ObjectDialog;