import React, { useState, useEffect } from 'react';
import callApi from "../../../../utils/call-api";
import { DataGrid } from '@mui/x-data-grid';
import ObjectDialog from '../../../common/ObjectDialog';



const ManualPricis = () => {
    const [students, setStudents] = useState([]);
    const [allPrices, setAllPrices] = useState([]);
    const [prices, setPrices] = useState([]);
    const [selectionStudentID, setSelectionStudentId] = useState([]);
    const [selectionStudent, setSelectionStudent] = useState({});
    const [selectionPriceID, setSelectionPriceId] = useState([]);
    const [selectionPrice, setSelectionPrice] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        callApi('dashboard/getStudents',"GET")
        .then(data=>{
            setStudents(data.data.students||[]);
        }).catch(err=>{
            console.log(err);
            setStudents([]);
        });

        callApi('price/allPrices',"GET")
        .then(data=>{
            setAllPrices(data.data.prices||[]);            
        }).catch(err=>{
            console.log(err);
            setAllPrices([]);
        });

    }, []);

    useEffect(() => {
        console.log(selectionStudentID);
        loadPricesByUser();
    }, [selectionStudentID]);

    const loadPricesByUser=()=>{
        if (selectionStudentID.length==0){
            setPrices([]);
            changeSelectionPrice([]);
            return;
        } 
        callApi('price/getPrices',"GET", {userId:selectionStudent.id, date: selectionStudent.creationDate})
        .then(data=>{
            const priceInfo = data.data.prices.map(item=>{
                return {
                    id: item.package.id,
                    packageName: item.package.name,
                    language: item.package.language.name,
                    lessonType: item.package.lessonType.description,
                    duration: item.package.duration.description,
                    lessonCount: item.package.count,
                    priceId: item.id,
                    price: item.price,
                    discount: item.discount
                };
            });
            setPrices(priceInfo||[]);            
        }).catch(err=>{
            console.log(err);
            setPrices([]);   
        })
    }

    const changeSelectionStudent = (row)=>{
        console.log(row);
        const studentId = row.filter(item=>item!=selectionStudentID[0])
        setSelectionStudentId(studentId);
        if (studentId.length>0) {
            setSelectionStudent(students.find(s=>s.id===studentId[0]));
        } else {
            setSelectionStudent({});
        }
    }
    const changeSelectionPrice = (row)=>{
        console.log(row);
        const priceId = row.filter(item=>item!=selectionPriceID[0]);
        setSelectionPriceId(priceId);
        if (priceId.length>0) {
            setSelectionPrice(prices.find(s=>s.id===priceId[0]));
            showPriceChangeDialog();
        } else {
            setSelectionPrice({});
        }
    }

    const showPriceChangeDialog = () => {
        setShowModal(true);
    }

    const onDialogOK= (manualPrices)=>{

        callApi('price/manualPrice',"POST",{
            user: selectionStudentID[0],
            package: selectionPriceID[0],
            price: manualPrices.price
        })
        .then(data=>{
            console.log(data);
            setShowModal(false);
            setSelectionPriceId([]);
            loadPricesByUser();

        }).catch(err=>{
            console.log(err);
            setShowModal(false);
            setSelectionPriceId([]);
        });
    }
    const onDialogCansel=()=>{
        setShowModal(false);
        setSelectionPriceId([]);
    }

    return (
        <div className='manual-pricis-container'>
            {/* <h1 className='manual-pricis-title'>ManualPricis</h1> */}
            <div className='manual-pricis-students'>
                <DataGrid
                    rows={students}
                    columns={[{field: 'id', headerName: 'ID', width: 90, editable: false},
                        {field: 'name', headerName: 'Имя', width: 150, editable: false},
                        {field: 'surname', headerName: 'Фамилия', width: 150, editable: false},
                        {field: 'email', headerName: 'E-mail', width: 150, editable: false}, 
                        {field: 'isActive', headerName: 'Активен', width: 150, editable: false},
                        {field: 'creationDate', headerName: 'Дата создания', width: 150, editable: false},
                    ]}
                    pageSize={5}
                    // selectionModel={selectionStudent}
                    rowSelectionModel={selectionStudentID}
                    onRowSelectionModelChange={changeSelectionStudent}
                    checkboxSelection
                    // onSelectionModelChange={changeSelectionStudent}
                >                                          
                </DataGrid>
            </div>
            <div className='manual-pricis-students'>            
                <DataGrid
                    rows={prices}
                    columns={[
                        {field: "packageName", headerName: "Название пакета", width: 150, editable: false}, 
                        {field: "language", headerName: "Язык", width: 150, editable: false},
                        {field: "lessonType", headerName: "Тип урока", width: 150, editable: false},
                        {field: "duration", headerName: "Продолжительность", width: 150, editable: false},
                        {field: "lessonCount", headerName: "Количество уроков", width: 150, editable: false},                        
                        {field: "price", headerName: "Цена", width: 90, editable: false},
                        {field: "discount", headerName: "Скидка", width: 90, editable: false}]}
                    pageSize={5}
                    rowSelectionModel={selectionPriceID}
                    onRowSelectionModelChange={changeSelectionPrice}
                    checkboxSelection
                    // onSelectionModelChange={changeSelectionPrice}
                />                
            </div>
            {showModal &&
            <ObjectDialog
                options={{title: `Изменить цену`,
                    columns:[
                    {field: "price", headerName: "Цена", type: "combobox", viseble:true,
                         list: allPrices.filter(item=>item.package_id===selectionPrice.id)
                        .map(item=>{return {id: item.priceId, value: item.price+""}}),
                         objectId: "id", objectValue: "value"},
                    
                ]}}
                value={{price:selectionPrice.id}}
                onOkClickCallBack={onDialogOK}
                onCanselClickCallBack={onDialogCansel}
            >
            </ObjectDialog>}
        </div>
    )
}

export default ManualPricis;