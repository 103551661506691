import React, { useState, useEffect } from 'react';

import {Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { SelectionState } from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableSelection
  } from '@devexpress/dx-react-grid-material-ui';
// import './CRUDGrid.css';
import imgAdd from './imgs/add.png';
import './CRUDGrid.scss';
import imgRemove from './imgs/remove.png';
import imgRefresh from './imgs/refresh.png';
import imgEdit from './imgs/edit.png';
import Paper from '@material-ui/core/Paper';

import ObjectDialog from './ObjectDialog';

/**
 * CRUDGrid - компонент для отображения табличных данных.
 * 
 * @param {Object} options - объект с настройками
 * @param {string} options.title - заголовок
 * @param {string} options.header - заголовок
 * @param {array} options.columns - массив с описанием колонок
 * @param {function} onRefreshCallBack - callback функция при обновлении данных
 * @param {function} onAddCallBack - callback функция при добавлении данных
 * @param {function} onRemoveCallBack - callback функция при удалении данных
 * 
 * @return {JSX.Element} - JSX-элемент
 */
const CRUDGrid = ({options, data, onRefreshCallBack, onAddCallBack, onRemoveCallBack})=>{
    const [header] = useState(options.header);
    const [title] = useState(options.title);
    const [columns] = useState(options.columns || []);
    const [selection, setSelection] = useState([1]);
    const [value, setValue] = useState({});
    const [objectArray, setObjectArray] = useState([]);

    useEffect(() => {
        // while(objectArray.length > 0) {
        //     objectArray.pop();
        // }
        setObjectArray(data);
        // data.forEach(datum => objectArray.push(datum));
    }, [data]);

    const [showModal, setShowModal]= useState(false);
    const [dialogTitle, setDialogTitle] = useState("");

    const changeSelection = (row)=>{
        console.log(row);
        setSelection(row.filter(item=>item!=selection[0]));
    }

    const onAdd = ()=>{
        setValue({});
        setDialogTitle("Добваить "+title);
        setShowModal(true);
    }

    const onEdit = ()=>{
        if (selection && selection.length>0){
            setValue(objectArray.find(item=>item.id===selection[0]));
            setDialogTitle("Изменить "+title);
            setShowModal(true);    
        }
    }

    const onRemove = ()=>{

    }

    const onRefresh = ()=>{
        onRefreshCallBack();
    }

    const onDialogOK = async (value)=>{
        await onAddCallBack(value);
        setShowModal(false);        
    }

    const onDialogCansel = ()=>{
        setShowModal(false);
    }
     
    return (<>
        <div className='crudDiv'>
            <div className="crudGridHeader">
                <div className='crudGridTitle'>
                    {title}
                </div>
                {header.refreshButton &&  <Button className="crudGridButton">
                    <img src={imgRefresh} alt=""
                    onClick={onRefresh} />
                </Button>}
           
                {header.removeButton &&  <Button  className="crudGridButton"> 
                    <img src={imgRemove} alt="" 
                    onClick={onRemove} />
                </Button>}
                {header.addButton &&  <Button  className="crudGridButton">
                    <img src={imgAdd} alt="" 
                    onClick={onAdd} />
                </Button>}
                {header.editButton &&  <Button  className="crudGridButton">
                    <img src={imgEdit} alt="" 
                    onClick={onEdit} />
                </Button>}
            </div>
            <div className='crudGrid'>
                <DataGrid
                    rows={objectArray}
                    columns={columns}
                    rowSelectionModel={selection}
                    onRowSelectionModelChange={changeSelection}
                    checkboxSelection
                >                               
                </DataGrid>

            </div>
        </div>
        {showModal && 
            <ObjectDialog
                options={{title: dialogTitle, columns: options.columns, exceptionTitle: options.exceptionTitle||{}}}
                value={value}
                onOkClickCallBack={onDialogOK}
                onCanselClickCallBack={onDialogCansel}
            >
            </ObjectDialog>}
    </>);

};

export default CRUDGrid;