import React, { useState, useEffect } from 'react';
import "./PricePackages.scss";
import lang from '../../../../utils/lang';

const PricePackageInfo = ({pricePackageInfo, onClickCallBack}) => {
    const [pricePackage, setPricePackage] = useState(pricePackageInfo);

    useEffect(() => setPricePackage(pricePackageInfo), [pricePackageInfo]);   

    return (<>{Object.keys(pricePackage).length !== 0 && <div class="PackageItem PackageItemScale" onClick={() => onClickCallBack(pricePackage)}>
    {pricePackage.hit && <div class="TopRightText">Хит</div>}                        
    <div class="CenterBlock">
        <div class="CenterHeader">{pricePackage.count}</div>
        <div class="CenterInfo">{`По ${pricePackage.language.name}`}</div>
        <div class="CenterInfo">{`${pricePackage.lessonType.description} занятий`}</div>
        <div class="CenterComment">{`(по ${pricePackage.duration.duration} минут)`}</div>
        <div class="CenterComment">{`${pricePackage.ageType.description} `}</div>
        <div class="CenterComment">{`с ${new Date(pricePackage.startDate*1000).toDateString()} до ${pricePackage.endDate? new Date(pricePackage.endDate*1000).toDateString(): "-"} до`}</div>
    </div>
</div>}</>);
};

export default PricePackageInfo;